/* eslint-disable no-unused-vars */
import Swal from "sweetalert2";

export const commonMixin = {
  methods: {
    manageAxiosErrors(err, moduleContentType = null) {
      /*
      let errMsg = "";

      if (err.response) {
        errMsg = err.response.data.message;
      } else if (err.request) {
        errMsg = err.data.message;
      }

      let alertMsg = this.$t('MESSAGEAUTH.0');

      if (errMsg) {
        let code = errMsg.split("|")[0].split(":")[1];
        alertMsg = this.$t('MESSAGEAUTH.' + code.toString())
      }
      */

      const [code, alertMsg] = this.getDataError(err);

      let extendedAlertMsg = "";
      if(code == 800) {
        extendedAlertMsg = this.$t('IDEADETAIL.CAMPOMANCANTE', { field:  this.$t('IDEADETAIL.MESSAGEFIELDNAME')});
      } else if(code == 810) {
        extendedAlertMsg = moduleContentType ? this.$t((this.$handleContentTypeLabel(moduleContentType) + "DETAIL.EMAILUTENTENONDISPONIBILE")) : "Error 810";
      } else if(code == 820) {
        extendedAlertMsg = moduleContentType ? this.$t((this.$handleContentTypeLabel(moduleContentType) + "DETAIL.EMAILAUTORENONDISPONIBILE")) : "Error 820";
      } else {
        extendedAlertMsg = alertMsg;
      }

      Swal.fire({
        title: this.$t('REGISTER.ATTENZIONE'),
        html: extendedAlertMsg,
        icon: "error",
        confirmButtonClass: "btn btn-secondary"
      });
    },

    getDataError(err) {
      let alertMsg = this.$t('MESSAGEAUTH.0');
      let errMsg = "";
      let code = 0;

      if (err.response) {
        errMsg = err.response.data.message;
      } else if (err.request) {
        errMsg = err.data.message;
      }

      if (errMsg) {
        code = errMsg.split("|")[0].split(":")[1];
        alertMsg = this.$t('MESSAGEAUTH.' + code.toString())
      }

      return [ code, alertMsg ];
    }
  }
};
