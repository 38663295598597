<template>

  <div
      class="login login-signin-on login-3 d-flex flex-row-fluid"
      id="kt_login"
  >
    <div
        class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
        style="background-image: url('/assets/media/bg/bg-3.jpg');"
    >
        <div class="login-form p-7 text-center position-relative overflow-hidden" style="min-width: 400px;">
          <h3 class="text-primary">{{$t("LOGINREGISTERPROCESSONPUBLIC.ORGANIZATION")}}</h3>
<!--          <p class="lead text-muted font-weight-bold">-->
<!--            {{$t("LOGINREGISTERPROCESSONPUBLIC.PAYOFF") /* #key_loc */}}-->
<!--          </p>-->
          <p class="mt-6 font-weight-bolder">{{$t("ADVAUTHMETHOD.PARTECIPAPROCESSO")}}</p>

          <div class="my-0">
            <a href="/">
              <img
                :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
                style="max-height: 160px; max-width: 260px"
                alt="Logo"
              />
            </a>
          </div>
          <div class="h5 mt-5 py-5 border-top border-top-2 dashed text-primary">{{ getOrganization.data.name }}</div>
          <div class="login-signup">

            <form class="form mt-5  text-left" @submit.stop.prevent="onSubmit">

              <div class="form-group mb-5">
                <input
                    class="form-control h-auto form-control-solid py-4 px-8"
                    type="text"
                    :placeholder="$t('REGISTER.PLACEHOLDERNOME') + ' *'"
                    name="name"
                    id="name"
                    v-model="$v.form.name.$model"
                    :state="validateState('name')"
                    aria-describedby="username-live-feedback"
                    autocomplete="off"
                    :class="{ invalid: $v.form.name.$dirty && $v.form.name.$invalid }"
                />
                <template v-if="$v.form.name.$dirty && $v.form.name.$invalid">
                <span v-if="!$v.form.name.required" class="error pl-5"
                >{{$t("REGISTER.NOMEDIMENTICATO")}}</span
                >
                </template>
              </div>
              <div
                  class="form-group mb-5">
                <input
                    class="form-control h-auto form-control-solid py-4 px-8"
                    type="text"
                    :placeholder="$t('REGISTER.PLACEHOLDERCOGNOME') + ' *'"
                    name="surname"
                    id="surname"
                    v-model="$v.form.surname.$model"
                    :state="validateState('surname')"
                    aria-describedby="username-live-feedback"
                    autocomplete="off"
                    :class="{
                  invalid: $v.form.surname.$dirty && $v.form.surname.$invalid
                }"
                />
                <template v-if="$v.form.surname.$dirty && $v.form.surname.$invalid">
                  <span v-if="!$v.form.surname.required" class="error pl-5">{{$t("REGISTER.COGNOMEDIMENTICATO")}}</span>
                </template>
              </div>
              <div class="form-group mb-5">
                <input
                    class="form-control h-auto form-control-solid py-4 px-8"
                    type="text"
                    :placeholder="$t('REGISTER.PLACEHOLDERUTENTE') + ' *'"
                    name="username"
                    id="username"
                    v-model="$v.form.username.$model"
                    :state="validateState('username')"
                    aria-describedby="username-live-feedback"
                    autocomplete="off"
                    :class="{
                  invalid: ($v.form.username.$dirty && ($v.form.username.$invalid || checkForbiddenCharacters('@', $v.form.username.$model)))
                }"
                />
                <template
                    v-if="$v.form.username.$dirty && ($v.form.username.$invalid || checkForbiddenCharacters('@', $v.form.username.$model))"
                >
                <div v-if="!$v.form.username.required" class="error pl-5"
                >{{$t("REGISTER.USERNAMEDIMENTICATO")}}</div
                >
                  <div v-if="checkForbiddenCharacters('@', $v.form.username.$model)" class="error pl-5">{{ $t("REGISTER.USERNAMEFORBIDDENCHARACTERS") }}</div>
                  <div v-if="$v.form.username.$model.length > 0 && $v.form.username.$model.length < $v.form.username.$params.minLength.min" class="error pl-5">{{ $t("REGISTER.USERNAMEMINLENGTH") }}</div>

                </template>
              </div>
              <div class="form-group mb-5">
                <input
                    class="form-control h-auto form-control-solid py-4 px-8"
                    type="email"
                    :placeholder="$t('REGISTER.PLACEHOLDEREMAIL') + ' *'"
                    name="email"
                    id="email"
                    v-model="$v.form.email.$model"
                    :state="validateState('email')"
                    aria-describedby="username-live-feedback"
                    autocomplete="off"
                    :class="{
                  invalid: $v.form.email.$dirty && $v.form.email.$invalid
                }"
                />
                <template v-if="$v.form.email.$dirty && $v.form.email.$invalid">
                <span v-if="!$v.form.email.required" class="error pl-5"
                >{{$t("REGISTER.MAILDIMENTICATA")}}</span
                >
                  <span v-if="!$v.form.email.email" class="error pl-5"
                  >{{$t("REGISTER.MAILNONVALIDA")}}</span
                  >
                </template>
              </div>
              <div class="form-group mb-5">
                <input
                    class="form-control h-auto form-control-solid py-4 px-8"
                    type="password"
                    :placeholder="$t('REGISTER.PLACEHOLDERPASSWORD') + ' *'"
                    name="password"
                    id="password"
                    v-model="$v.form.password.$model"
                    :state="validateState('password')"
                    aria-describedby="username-live-feedback"
                    autocomplete="off"
                    :class="{
                  invalid: $v.form.password.$dirty && $v.form.password.$invalid
                }"
                />
                <template
                    v-if="$v.form.password.$dirty && $v.form.password.$invalid"
                >
                <span v-if="!$v.form.password.required" class="error pl-5"
                >{{$t("REGISTER.PASSWORDDIMENTICATA")}}</span
                >
                </template>
              </div>

              <div class="form-group mb-5 text-left">
                <label class="checkbox m-0">
                  <input
                      type="checkbox"
                      name="term_and_conditions"
                      v-model="term_and_conditions"/>{{$t("REGISTER.BACCETTO")}}
                  <router-link
                      to="/terms"
                      target="_blank"
                      class="font-weight-bold"
                  >{{$t("REGISTER.TERMINICONDIZIONI")}}</router-link
                  >. <span></span
                ></label>
                <div class="form-text text-muted text-center"></div>
              </div>
              <div class="form-group mb-5 text-left">
                <label class="checkbox m-0">
                  <input type="checkbox" name="privacy" v-model="privacy"/>{{$t("REGISTER.ACCETTOLA")}}
                  <router-link to="/privacy" target="_blank">
                    {{$t("REGISTER.PRIVACY")}}</router-link
                  >. <span></span
                ></label>
                <div class="form-text text-muted text-center"></div>
              </div>
              <div class="form-group mb-5 text-left">
                <label class="checkbox m-0">
                  <input
                      type="checkbox"
                      name="newsletter"
                      v-model="newsletter"/>{{$t("REGISTER.NEWSLETTER")}}<span></span
                ></label>
                <div class="form-text text-muted text-center"></div>
              </div>

              <div class="form-group d-flex flex-wrap text-center">
                <vue-recaptcha sitekey="6Lc10EkbAAAAAAaNg5K4SeEriJSMIp5YHg9wbGSr" @verify="verifyReCaptcha" @expired="resetReCaptcha" :loadRecaptchaScript="false"></vue-recaptcha>
              </div>

              <div class="form-group d-flex flex-wrap text-center">
                <button
                    :disabled='isDisabled'
                    class="btn btn-lg btn-primary btn-block text-uppercase font-weight-bolder"
                    ref="kt_register_submit"
                >{{$t("REGISTER.BREGISTRA")}}</button>
              </div>

            </form>
            <div class="text-muted font-weight-bold">{{$t("REGISTER.GIAACCOUNT")}}
              <router-link
                  class="text-primary font-weight-bolder"
                  :to="{ name: 'login', query: { confirmedEmail: 'true' } }">
                <span class="font-weight-bolder text-uppercase">{{$t("REGISTER.BACCEDI")}}</span>
                <i class="menu-arrow"></i>
              </router-link>
            </div>
          </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  REFRESH_TOKEN,
  REGISTER_TO_ORGANIZATION,
  // LOGOUT
} from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import {commonMixin} from "@/mixins/common";
import {alertMixin} from "@/mixins/alert";
import VueRecaptcha from 'vue-recaptcha';

import { email, minLength, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
// import ApiService from "@/core/services/api.service";
// import Swal from "sweetalert2";
// import jwt_decode from "jwt-decode";
// import store from "@/core/services/store";
import i18nService from "@/core/services/i18n.service.js";

// const mustBeCool = () => !helpers.req('@') || /@/.test(this.$v.form.username.$model) >= 0;

export default {
  mixins: [validationMixin, commonMixin, alertMixin],
  name: "register",

  data() {
    return {
      recaptcha: false,
      term_and_conditions: false,
      privacy:false,
      newsletter: false,
      form: {
        name: "",
        surname: "",
        username: "",
        email: "",
        password: "",
      }
    };
  },
  validations: {
    form: {
      name: {
        required
      },
      surname: {
        required,
      },
      username: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      },
    },
  },
  components: {
    VueRecaptcha
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters([
      "getOrganization"
    ]),
    isDisabled: function(){
      return !this.privacy || !this.term_and_conditions || !this.recaptcha;
      //return !this.privacy || !this.term_and_conditions;
    },
    getLogo() {
      return (this.getOrganization.data) ? this.getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'
    },
  },
  mounted() {
    this.loadRecaptcha();
  },
  methods: {
    checkForbiddenCharacters(char, target) {
      let regex = new RegExp(char)
      return regex.test(target);
    },
    loadRecaptcha () {
      const url = `https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=` + i18nService.getActiveLanguage();
      const $el = document.createElement('script');
      $el.src = url;
      $el.async = true;
      document.head.appendChild($el);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? (!$error && !this.checkForbiddenCharacters('@', this.$v.form.username.$model)) : null;
    },
    resetForm() {
      this.form = {
        name: null,
        surname: null,
        username: null,
        email: null,
        password: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    verifyReCaptcha(response) {
      if(response != null && response != '') {
        this.recaptcha = true;
      }
    },

    resetReCaptcha() {
        this.recaptcha = false;
    },

    async onSubmit() {
      this.$v.form.$touch();

      if(!this.recaptcha) {
        return;
      }

      if (this.$v.form.$anyError) {
        return;
      }

      if(!this.term_and_conditions || !this.privacy) {
        return;
      }

      if (this.checkForbiddenCharacters('@', this.$v.form.username.$model)) {
        return
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_register_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let registrationData = {
        username: this.$v.form.username.$model,
        password: this.$v.form.password.$model,
        email: this.$v.form.email.$model,
        name: this.$v.form.name.$model,
        surname: this.$v.form.surname.$model,
        termsConditionsAccepted: this.term_and_conditions,
        privacyPolicyAccepted: this.privacy,
        subscribedToNewsletter: this.newsletter,
        organizationDomain: this.$currentDomain,
        callbackPath: "email-confirm",
        groupId: null,
        locale: i18nService.getActiveLanguage()
      };

      await this.$store.dispatch(REGISTER_TO_ORGANIZATION, registrationData)
          .then(response => {
            if (response.status == 200 || response.status == 201) {
              this.$store.dispatch(REFRESH_TOKEN, response.headers.token);
              this.alertUserRegisteredToOrganization();
              this.$router.push('home');
            } else {
              Swal.fire(
                  "Error",
                  "Ops... something went wrong; Stauts code: " + response.status,
                  "info"
              );
            }
            submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
            );
          })
          .catch(err => {
            this.manageAxiosErrors(err);
            submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
          });
    },
  }
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
.error{
  color: #ff0000;
}
.invalid{
  border-color: #ff0000;
}

</style>

